<template>
	<div>
		<m1 :mdata="informationdata.information"></m1>
	</div>
</template>

<script>
	import m1 from '@/components/common_components/m1.vue'

	export default {
		components: {
			m1
		},
		data() {
			return {
				informationdata:{
					information:[],
				}
			}
		},
		created() {
			this.$post("/industry/categoryall",{category:"行业资讯"}).then(resp=>{
				this.informationdata.information = resp.dataList
			})
		},
		watch:{
			$route(newdata,olddata) {
				console.log(newdata)
				console.log(olddata)
			}
		}
	}
</script>

<style>
</style>

<template>
	<div>
		<div class="bode">
			<div class="bode_left">
				<div class="main">
					<div class="container f-cb">
						<div class="leMenu">
							<div class="title">
								<h1>{{ categorydata }}</h1>
								<p></p>
							</div>
							<ul class="big" v-for="(item,key) in mdata" :key="key">
								<li><a href="#" class="act">{{ item.headline }}</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="mess-w" v-if="details !== true">
				<div class="bode_right">
					<div class="headmassage">
						<a>{{ categorydata }}</a>
					</div>
					<div class="center1-lm2" id="containet" v-cloak>
						<ul id="pageMain">
							<li v-for="(item,key) in datafrom" :key="key">
								<a target="_blank" :title="item.title" @click="opentitle(item)">{{ item.title }}</a>
							</li>
						</ul>
					</div>
					<div class="block">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="current" :page-sizes="sizes" :page-size="size"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>

			<div class="mess-w" v-if="details">
				<div class="bode_right" style="padding-top: 20px;">
					<el-page-header @back="goBack" :content="headline">
					</el-page-header>
					<div
						style="text-align: center;padding-bottom: 25px;padding-top: 20px;font-weight: bold;font-size: 20px;">
						{{ title }}
					</div>
					<div class="cont-img ql-editor" v-html="content" style="padding-top:10px;padding-bottom: 10px;max-width: 100%;"></div>
					<div style="font-weight: 600;text-align: right;padding-top: 25px;">{{ time }}</div>
					<div style="padding-top: 10px;text-align: right;padding-bottom: 25px;font-weight: 600;">{{ source}}</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		props: ['mdata'],
		methods: {
			handleSizeChange(val) {
				this.size = val
				this.$post("/industry/pageindustry", {
					headline: this.datafrom[0].headline,
					current: this.current,
					size: this.size
				}).then(resp => {
					this.datafrom = resp.data.records
				})
			},
			handleCurrentChange(val) {
				this.current = val
				this.$post("/industry/pageindustry", {
					headline: this.datafrom[0].headline,
					current: this.current,
					size: this.size
				}).then(resp => {
					this.datafrom = resp.data.records
				})
			},
			opentitle(data) {
				this.details = true
				this.title = data.title
				this.content = data.content
				this.time = data.creatTime
				this.headline = data.headline
				this.source = data.source
			},
			goBack() {
				if (this.$route.query.firstdata !== undefined) {
					this.details = false
					this.$router.push("/pc")
					/* this.categorydata = this.mdata[0].category
					this.size = this.sizes[0]
					this.$post("/industry/pageindustry", {
						headline: this.mdata[0].headline,
						current: 1,
						size: this.size
					}).then(resp => {
						this.datafrom = resp.data.records
						this.total = resp.data.total
					}) */
				} else {
					this.details = false
				}
			}
		},
		data() {
			return {
				current: 1,
				sizes: [10, 20, 30, 50],
				size: null,
				total: 0,
				categorydata: "",
				datafrom: [],
				title: '',
				content: '',
				time: '',
				details: false,
				headline: '',
				source: ''
			};
		},
		created() {

		},
		watch: {
			mdata(newdata) {
				this.categorydata = newdata[0].category
				if (this.$route.query.firstdata !== undefined) {
					this.opentitle(this.$route.query.firstdata)
				} else {
					this.details = false
					this.size = this.sizes[0]
					this.$post("/industry/pageindustry", {
						headline: newdata[0].headline,
						current: 1,
						size: this.size
					}).then(resp => {
						this.datafrom = resp.data.records
						this.total = resp.data.total
					})
				}
			}
		}
	}
</script>

<style scoped="scoped">
	@import '../../assets/css/cont.css';
	@import '../../assets/css/index.css';
	@import "~@/assets/css/richText.css";
	.center1-lm2 ul li {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: 0 !important;
		width: 100%;
		box-sizing: border-box;
		margin-top: 19px;
		font-size: 16px;
		color: #333;
		line-height: 16px;
		text-indent: 1em;
		cursor: pointer;
	}

	#pageMain li {
		list-style: none;
		line-height: 22px;
	}

	.el-pagination {
		margin-top: 10px;
		text-align: center;
		bottom: 50px;
		white-space: nowrap;
		padding: 2px 5px;
		color: #303133;
		font-weight: 700;
	}

	.el-pagination[data-v-195f7b39] {
		margin-top: 20px;
		margin-bottom: 10px;
	}

	[v-cloak] {
		display: none;
	}

	.mess-text p {
		margin-top: 5px;
		font-size: 16px;
	}

	.cont-img>>>img {
		max-width: 100%;
	}
</style>
